/* line 5, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.a-dictionary-all-words {
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 22px;
  padding: 10px 0;
  margin-top: 20px; }
  /* line 12, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
  .a-dictionary-all-words:hover {
    background-color: black;
    color: white; }

/* line 18, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.a-word {
  padding-left: 3px;
  padding-right: 3px; }

/* line 23, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.a-word-tooltip {
  background-color: white;
  position: absolute;
  display: block;
  width: 320px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  padding: 10px 15px;
  z-index: 999; }

/* line 37, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.m-dictionary-word h3 {
  font-weight: 800;
  font-size: 20px;
  display: inline;
  padding-right: 5px; }

/* line 43, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.m-dictionary-word p {
  font-weight: 200;
  font-size: 18px;
  display: inline; }

@media all and (min-width: 768px) {
  /* line 52, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
  .m-dictionary-word h3 {
    font-size: 30px; }
  /* line 55, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
  .m-dictionary-word p {
    font-size: 26px; } }

/* line 65, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.o-dictionary {
  color: white; }

/* line 70, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
.o-dictionary-words h3 {
  border-bottom: 1px solid;
  padding-bottom: 15px;
  margin-bottom: 15px; }
  /* line 74, /Users/anders/Documents/Almega-Fokus/web/wp-content/plugins/bentoWP_plugin_dictionary/src/sass/_patternlab.scss */
  .o-dictionary-words h3:not(:first-child) {
    padding-top: 30px !important; }
