
// ATOMS
//==============================================================================================================

.a-dictionary-all-words {
	background-color: white;
	text-align: center;
	text-transform: uppercase;
	font-weight: 22px;
	padding: 10px 0;
	margin-top: 20px;
	&:hover {
		background-color: black;
		color: white;
	}
}

.a-word-tooltip {
	background-color: white;
	position: absolute;
	display: block;
	width: 320px;
	box-shadow: 0 0 4px rgba(0,0,0,.5);
	padding: 10px 15px;
	z-index: 999;
}

// MOLECULES
//==============================================================================================================

.m-dictionary-word {
	h3 {
		font-weight: 800;
		font-size: 20px;
		display: inline;
		padding-right: 5px;
	}
	p {
		font-weight: 200;
		font-size: 18px;
		display: inline;
	}
}

@media all and (min-width: $beta_screen * 1px) {
	.m-dictionary-word {
		h3 {
			font-size: 30px;
		}
		p {
			font-size: 26px;
		}
	}
}


// ORGANISMS
//==============================================================================================================

.o-dictionary {
	color: white;
}

.o-dictionary-words {
	h3 {
		border-bottom: 1px solid;
		padding-top: 30px !important;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}
}


// STATES
//==============================================================================================================


// ANIMATIONS
//==============================================================================================================
